import { PUBLIC_SENTRY_DSN_WEB, PUBLIC_SST_STAGE } from '$env/static/public'
import { handleErrorWithSentry } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'
import { createLogger } from '@puebla/logger'
import { baseConfig, feedback, replay, replayConfig } from '$lib/sentry.js'

const logger = createLogger('client-hooks')
logger.info('stage', PUBLIC_SST_STAGE)

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN_WEB,
	environment: PUBLIC_SST_STAGE,
	...baseConfig(PUBLIC_SST_STAGE),
	...replayConfig(PUBLIC_SST_STAGE),
	integrations: [replay!, feedback!]
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()

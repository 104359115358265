import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [~4,[2]],
		"/(app)/analytics": [5,[2]],
		"/(app)/buy": [~6,[2]],
		"/(app)/buy/broadcast": [~7,[2]],
		"/(public)/forgot-password": [~22,[3]],
		"/(public)/invite": [~23,[3]],
		"/(public)/invite/invalid": [24,[3]],
		"/(public)/invite/success": [25,[3]],
		"/(public)/login": [~26,[3]],
		"/(public)/logout": [~27,[3]],
		"/(app)/negotiation/bid": [~16,[2]],
		"/(app)/negotiation/offer": [~17,[2]],
		"/(app)/negotiation/[negotiationId]": [~8,[2]],
		"/(app)/negotiation/[negotiationId]/accept": [~9,[2]],
		"/(app)/negotiation/[negotiationId]/cancel": [~10,[2]],
		"/(app)/negotiation/[negotiationId]/contract/[contractId]": [~11,[2]],
		"/(app)/negotiation/[negotiationId]/contract/[contractId]/addProducts": [~12,[2]],
		"/(app)/negotiation/[negotiationId]/contract/[contractId]/finalize": [~13,[2]],
		"/(app)/negotiation/[negotiationId]/counter": [~14,[2]],
		"/(app)/negotiation/[negotiationId]/reject": [~15,[2]],
		"/(public)/new-password": [~28,[3]],
		"/(app)/positions": [18,[2]],
		"/(public)/reset-password": [~29,[3]],
		"/(app)/sell": [~19,[2]],
		"/(app)/sell/broadcast": [~20,[2]],
		"/(app)/settings": [~21,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';